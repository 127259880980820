import { Link } from "gatsby";
import * as React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface Article {
    id: string;
    slug: string;
    title: string;
    date: string;
    lead: string;
    featuredImage: any;
    type: string;
}

interface ArticlesProps {
    articles: Article[];
    title?: string;
    lead?: string;
}

export const Articles: React.FC<ArticlesProps> = ({ articles, title, lead }) => {
    return (
        <div>
            {title ? (
                <div className={"mainheading"}>
                    <h1>{title}</h1>
                    <p className="lead">{lead}</p>
                </div>
            ) : null}
            <Container>
                <Row>
                    {articles.map((node: Article) => {
                        const featuredImg = getImage(
                            node.featuredImage?.childImageSharp?.gatsbyImageData,
                        );

                        return (
                            <Col xs={12} md={6} lg={4} xl={3} key={`/${node.type}${node.slug}`}>
                                <Card>
                                    {featuredImg ? (
                                        <GatsbyImage image={featuredImg} alt={node.title} />
                                    ) : null}
                                    <Card.Body>
                                        <Card.Title>
                                            <Link to={`/${node.type}${node.slug}`}>
                                                {node.title}
                                            </Link>
                                        </Card.Title>
                                        <Card.Text>{node.lead}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
    );
};
