import * as React from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import moment from "moment";
import { Articles } from "../components/articles";
import { useMemo } from "react";
import { blogPosts } from "../utils/data";

const TagTemplate = ({
    data: { allMarkdownRemark, site, markdownRemark: post  },
}: any) => {
    const siteTitle = site.siteMetadata?.title || `Title`;

    const articles = useMemo(() => blogPosts.convert({allMarkdownRemark}), [allMarkdownRemark]);

    const htmlWithIDs = post.html;

    const featuredImg = getImage(
        post.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData,
    );

    return (
        <Layout pageTitle={siteTitle} activeKey={"/"}>
            <article className="blog-post">
                <header>
                    <h1>{post.frontmatter.h1}</h1>
                    {featuredImg ? (
                        <GatsbyImage image={featuredImg} alt={post.frontmatter.title} loading={"eager"} />
                    ) : null}
                </header>
                <section dangerouslySetInnerHTML={{ __html: htmlWithIDs }} />
            </article>
            <Articles articles={articles} />
        </Layout>
    );
};

export const Head = ({ data: { markdownRemark: post } } : any) => {
    return (
        <Seo
            title={post.frontmatter.title}
            shortTitle
            description={post.frontmatter.description || post.excerpt}
            url={"/tag" + post.fields.slug}
        />
    );
};

export default TagTemplate;

export const pageQuery = graphql`
    query BlogPostsByTag($id: String!, $tag: String!) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { tags: { eq: $tag } } }
            sort: { frontmatter: { date: DESC } }
        ) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    type
                    date(formatString: "MMMM DD, YYYY")
                    title
                    lead
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                h1
                title
                date(formatString: "MMMM DD, YYYY")
                description
                featuredImage {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            fields {
                slug
            }
        }
    }
`;
